import React from "react";
import useNavigation from "../Navigation/Navigation";
import logoutBlack from "../../images/logoutblack.svg";
import "./SavedNewsHeader.css";

function SavedNewsHeader({ name, onLogout }) {
  const { navigateHome, navigateSavedArticles } = useNavigation();

  return (
    <header className="saved-header">
      <div className="saved-header__content">
        <button
          className="saved-header__button saved-header__button--news-explorer"
          onClick={navigateHome}
        >
          NewsExplorer
        </button>

        <div className="saved-header__login-area">
          <button
            className="saved-header__button saved-header__button--home"
            onClick={navigateHome}
          >
            Home
          </button>
          <button
            className="saved-header__button saved-header__button--articles"
            onClick={navigateSavedArticles}
          >
            Saved articles
          </button>
          <button
            className="saved-header__button saved-header__button--name"
            onClick={onLogout}
          >
            {name}
            <img
              className="saved-header__icon saved-header__icon--logout"
              src={logoutBlack}
              alt="Logout"
            />
          </button>
        </div>
      </div>
    </header>
  );
}

export default SavedNewsHeader;
